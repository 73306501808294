<script>
  import {createEventDispatcher} from "svelte";
  import MessageBox from "~/components/MessageBox.svelte";
  import { messages } from "@/stores/flash.js";

  export let cta

  const dispatch = createEventDispatcher();
</script>

{#each $messages as {type, message}}
  {#await message then m}
    <MessageBox {type} {cta} on:cta={() => dispatch('cta')}>{@html m}</MessageBox>
  {/await}
{/each}