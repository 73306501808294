<script>
  import { csrfToken, csrfParam } from "@/stores/csrf.js";
  import { setContext } from "svelte";
  import { formValidationErrors, formValues } from "@/stores/flash.js";

  export let action;

  setContext('values', $formValues);
  setContext('validation-errors', $formValidationErrors)
</script>

<form on:submit {action} method="post" class={$$props.class}>
  <input type="hidden" name="utf8" value="✓"/>
  {#if $csrfToken}
    <input type="hidden" name={$csrfParam} value="{$csrfToken}"/>
  {/if}
  <slot/>
</form>